<template>
  <div class="container">
    <div v-if="loading">加载中...</div>
    <div v-else>
      <div class="content">
        <div class="image-container">
          <img :src="require('@/assets/path/1.jpg')" alt="图片" class="image" />
        </div>
        <div class="card">
          <h3>私域课程</h3>
          <p class="price">￥0.01</p>
        </div>
        <div class="details">
          <p class="description">详细介绍:xxxxxxxxxxx</p>
        </div>
        <div class="image-container">
          <img :src="require('@/assets/path/2.jpg')" alt="图片" class="image" />
        </div>

 <!--
        <div class="openid" v-if="openid"  style="visibility: hidden;">
          <p>用户 OpenID: {{ openid }}</p>
        </div>

        <div class="user" v-if="user.nickname"  style="visibility: hidden;">
          <p>用户 OpenID: {{ user.openid }}</p>
          <p>用户 昵称: {{ user.nickname }}</p>
          <img :src="user.avatarUrl" alt="用户头像" class="avatar" />
        </div>
  -->


      </div>
      <button @click="checkUserAndHandleWeChatPay" :class="{'wechat-pay-button': true, 'bottom-fixed': isAuthorized}" class="wechat-pay-button">微信支付 点右下角完成服务</button>

      <div v-if="!isAuthorized" class="footer">
        <div class="warning-container">
          <div class="warning-icon">❗</div>
          <div class="warning-text">
            <p>该网页需要获取个人信息才可使用完整服务，当前仅可浏览部分内容。</p>
          </div>
        </div>
        <a href="#" @click="useFullService" class="complete-service-link">使用完整服务 &gt;</a>
      </div>
    </div>
  </div>
</template>

<script>
/* global WeixinJSBridge */  // 添加这行声明 WeixinJSBridge 为全局变量
export default {
  name: 'HomePage',
  data() {
    return {
      isAuthorized: false,
      openid: null,
      user: {
        openid: '',
        nickname: '',
        avatarUrl: ''
      },
      loading: true,  // 新增loading状态
      scope: null, // 新增scope变量
      agentCode: null  // 添加 agentCode 属性
    };
  },

mounted() {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const state = urlParams.get('state');
  let scope = urlParams.get('scope');
  const agentCode = urlParams.get('agn');  // 提取 agn 参数

  // 如果 scope 参数为空，从 state 参数中解析
  if (!scope && state) {
    const stateParams = new URLSearchParams(state);
    scope = stateParams.get('scope');
  }
this.scope = scope; // 保存scope的值
this.agentCode = agentCode;  // 保存 agn 参数到组件的数据属性
    console.log('Mounted with code:', code, 'scope:', scope, 'agent code:', agentCode); // 调试日志

  if (code) {
    if (scope === 'snsapi_userinfo') {
      console.log('Calling getUserInfo');
      this.getUserInfo(code);
    } else {
      console.log('Calling getOpenID');
      this.getOpenID(code);
    }
  } else {
    console.log('Redirecting to WeChat auth');
    this.redirectToWeChatAuth();
  }
}
,

  methods: {
    redirectToWeChatAuth(scope = 'snsapi_base') {
      const appId = 'wx850bf59272efeae3'; // 替换为你的微信App ID
      const redirectUri = encodeURIComponent(`${window.location.origin}${window.location.pathname}`);
      const state = `scope=${scope}`;
      const weChatAuthUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
      console.log('Redirecting to WeChat auth with URL:', weChatAuthUrl); // 调试日志

      window.location.href = weChatAuthUrl;
    },
    redirectToWeChatAuthMore() {
      this.redirectToWeChatAuth('snsapi_userinfo');
    },
    async getOpenID(code) {
      try {
        const response = await fetch(`/api/wechat/openid?code=${code}`);
        const data = await response.json();
        console.log('OpenID response:', data); // 调试日志
        if (data.openid) {
          this.openid = data.openid;
          // 检查 OpenID 是否在数据库中
          const checkResponse = await fetch(`/api/wechat/check_openid?openid=${data.openid}`);
          const checkData = await checkResponse.json();
          this.isAuthorized = checkData.exists; // 根据检查结果更新授权状态
          if (this.isAuthorized) {
            localStorage.setItem('isAuthorized', 'true'); // 存储授权状态
          }
        } else {
          console.error('Failed to fetch OpenID:', data.error);
          if (data.error === "code been used") {
            this.redirectToWeChatAuth();
          }
        }
      } catch (error) {
        console.error('Failed to fetch OpenID:', error);
      } finally {
        this.loading = false; // 停止加载状态
      }
    },
async getUserInfo(code) {
    try {
      console.log('Fetching user info with code:', code); // 增加日志
      const response = await fetch(`/api/wechat/userinfo?code=${code}`);
      const data = await response.json();
      console.log('User info response:', data); // 调试日志

      if (data.openid) {
        this.user.openid = data.openid;
        this.user.nickname = data.nickname;
        this.user.avatarUrl = data.headimgurl; // 确保头像 URL 被正确设置

        console.log('Checking if openid exists in database'); // 增加日志
        const checkResponse = await fetch(`/api/wechat/check_openid?openid=${data.openid}`);
        const checkData = await checkResponse.json();
        console.log('Check openid response:', checkData); // 调试日志

        this.isAuthorized = checkData.exists;
        if (this.isAuthorized) {
          localStorage.setItem('isAuthorized', 'true');
        } else {
          console.log('Saving user info to database'); // 增加日志
          await this.saveUserInfo({
            openid: this.user.openid,
            nickname: this.user.nickname,
            avatarUrl: this.user.avatarUrl
          });
          this.isAuthorized = true; // 设置为已授权
        }
      } else {
        console.error('Failed to fetch user info:', data.error);
        if (data.error === "code been used") {
          this.redirectToWeChatAuthMore();
        }
      }
    } catch (error) {
      console.error('Failed to fetch user info:', error);
    } finally {
      this.loading = false; // 停止加载状态
    }
  }
,
    useFullService() {
          this.redirectToWeChatAuthMore();
        },

    async saveUserInfo(data) {
        try {
            const response = await fetch('/api/wechat/save_user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                console.error('Failed to save user info');
            } else {
                console.log('User info saved successfully');
            }
        } catch (error) {
            console.error('Error saving user info:', error);
        }
    },

     async checkUserAndHandleWeChatPay() {
       let openidToUse = this.scope === 'snsapi_userinfo' ? this.user.openid : this.openid;

       try {
           // 检查支付状态
           const checkResponse = await fetch(`/api/wechat/check_user_and_payment_status?openid=${openidToUse}`, { cache: "no-store" });
           const checkData = await checkResponse.json();

           console.log("Check Data:", checkData); // 添加日志输出

           if (checkData.paymentPaid) {
               // 如果订单已经支付，直接跳转页面
               window.location.href = "/about";
           } else {
               // 如果订单未支付，处理支付
               await this.handleWeChatPay();
           }
       } catch (error) {
           console.error("Error checking payment status:", error);
       }
     }

     ,

    async handleWeChatPay() {
      try {
      let openidToUse = this.scope === 'snsapi_userinfo' ? this.user.openid : this.openid;
        const response = await fetch('/api/wechat/pay', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ openid: openidToUse, agentCode: this.agentCode }) // 将 agentCode 包含在支付请求中
        });
        const data = await response.json();

        if (data.success) {
          // 使用微信JS-SDK发起支付
          const payParams = {
            appId: data.appId,
            timeStamp: data.timeStamp,
            nonceStr: data.nonceStr,
            package: data.package,
            signType: data.signType,
            paySign: data.paySign
          };
          console.log(payParams); // 打印 payParams 检查是否包含 appId

          WeixinJSBridge.invoke('getBrandWCPayRequest', payParams, function (res) {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              // 支付成功
              alert('支付成功');
              // 支付成功，跳转到about页面
              window.location.href = "/about";
            } else {
              // 支付失败
              alert('支付失败');
            }
          });
        } else {
          alert('生成支付订单失败');
        }
      } catch (error) {
        console.error('Failed to initiate WeChat Pay:', error);
        alert('支付请求失败');
      }
    }
  },
  watch: {
    loading(newValue) {
      if (!newValue) {
        console.log('Loading finished'); // 调试日志
      }
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

.content {
  flex: 1;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.image-container {
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card {
  width: 100%;
  padding: 12px;
  margin-bottom: 5px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.card h3 {
  margin-bottom: 0px;
  font-size: 16px;
  color: #333;
}

.card .price {
  font-size: 16px;
  color: #e74c3c;
  margin-bottom: 0px;
}

.details {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.details .description {
  font-size: 13px;
  color: #555;
  margin-bottom: 2px;
  padding-bottom: 2px;
}

.openid {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  font-size: 13px;
  color: #555;
}

.user-info {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  font-size: 13px;
  color: #555;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: black;
  color: white;
  font-size: 10px;
}

.warning-container {
  display: flex;
  align-items: center;
  flex: 1;
}

.warning-icon {
  color: red;
  margin-right: 5px;
}

.warning-text {
  font-size: 10px;
  color: #ccc; /* 灰色文字 */
}

.complete-service-link {
  margin-left: 20px;
  margin-right: 30px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 12px;
  font-weight: 600; /* 使用较细的字体 */
  font-family: 'Helvetica Neue', 'Roboto Light', sans-serif; /* 替换为细字体 */
}

.wechat-pay-button {
  height: 50px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%); /* 居中 */
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Helvetica Neue', 'Roboto Light', sans-serif;
  background-color: #1AAD19; /* 将颜色改为浅绿色 */
  border: none;
  padding: 10px 0; /* 高度铺满 */
  width: 80%; /* 宽度铺满 */
  border-radius: 5px;
  cursor: pointer;
}

.bottom-fixed {
  bottom: 0;
}

.wechat-pay-button:not(.bottom-fixed) {
  bottom: 80px;
}

</style>
