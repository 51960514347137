<template>
  <el-container>
    <el-aside width="200px">
      <el-menu default-active="1" class="el-menu-vertical-demo">
        <el-menu-item index="1">
          <i class="el-icon-s-order"></i>
          <template v-slot:title>
            <span>订单</span>
          </template>
        </el-menu-item>

      </el-menu>
    </el-aside>

    <el-container>
      <el-header>
        <span>订单管理</span>
      </el-header>

      <el-main>
        <el-card>
          <template v-slot:header>
            <div class="clearfix">
              <span>列表</span>
              <el-button style="float: right;" type="primary" size="mini" @click="refreshOrders">刷新</el-button>
            </div>
          </template>

          <el-table :data="orders" style="width: 100%">
            <el-table-column label="序号" width="50">
              <template v-slot:default="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="agent" label="代理" width="100"></el-table-column>
            <el-table-column prop="orderId" label="订单编号"></el-table-column>
            <el-table-column prop="openid" label="Openid"></el-table-column>
            <el-table-column prop="nickname" label="用户昵称"></el-table-column>
            <el-table-column prop="totalAmount" label="订单金额" width="100"></el-table-column>
            <el-table-column prop="payStatus" label="状态" width="100">
              <template v-slot:default="scope">
                <el-tag :type="scope.row.payStatus === '已支付' ? 'success' : 'warning'">
                  {{ scope.row.payStatus }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="payMethod" label="支付方式" width="100"></el-table-column>
            <el-table-column prop="payTime" label="支付时间" width="180">
              <template v-slot:default="scope">
                {{ formatTime(scope.row.payTime) }}
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: 'OrderManagement',
  data() {
    return {
      orders: []
    };
  },
  methods: {
    refreshOrders() {
      fetch('/api/orders')
        .then(response => response.json())
        .then(data => {
          console.log('Fetched orders:', data); // 打印获取到的数据
          this.orders = data;
        })
        .catch(error => {
          console.error('Error fetching orders:', error);
        });
    },
    formatTime(dateTime) {
      if (!dateTime) return '';
      const date = new Date(dateTime);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    }
  },
  created() {
    this.refreshOrders();
  }
};
</script>

<style scoped>
.el-menu-vertical-demo {
  width: 100%;
  height: 100%;
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
  text-align: left;
  padding-left: 20px;
  font-size: 20px;
}

.el-card {
  margin-top: 20px;
}

.el-button {
  margin-right: 10px;
}
</style>
