<template>
  <div class="video-container">
    <h1>视频展示</h1>
    <video controls class="video-player">
      <source :src="require('@/assets/video/3.mp4')" type="video/mp4">
      你的浏览器不支持 HTML5 视频标签。
    </video>
    <p>这是一个视频展示页面。</p>
  </div>
</template>

<script>
export default {
  name: 'VideoPage'
}
</script>

<style scoped>
.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  color: blue;
  margin-bottom: 20px;
}

.video-player {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

p {
  font-size: 16px;
  color: #555;
  text-align: center;
}
</style>



